import React, { useEffect, useState } from "react"
import Tree from "../images/referral-tree.png"
import styled from "styled-components"

const MainContainer = styled.div`
  @media (min-width: 500px) {
    display: grid;
    grid-template-columns: 1fr 3fr;
  }
`

const ReferralCard = ({ refCode, refData, userData }) => {
  // const { isLoading, profile } = useAuth()

  const handleCopy = e => {
    var url = document.createElement("textarea")
    url.innerText = document.getElementById("ref_link").value
    document.body.appendChild(url)
    url.select()
    document.execCommand("copy")
    url.remove()
    setBackgroundColor("var(--green)")
    setCopyColor("white")
    setCopyText("Copied!")
  }

  const [copyColor, setCopyColor] = useState("black")
  const [backgroundColor, setBackgroundColor] = useState("#D3D3D3")
  const [copyText, setCopyText] = useState("Copy")

  return (
    <div className="card" style={{ maxWidth: "50rem", marginTop: "2rem" }}>
      <div
        className="card-content"
        style={{ maxWidth: "50rem", alignItems: "center", display: "flex" }}
      >
        <MainContainer
          style={{
            alignItems: "center",
          }}
        >
          <img src={Tree} alt="Tree" style={{ width: "100%" }} />
          <div style={{ padding: "1rem" }}>
            <h3 style={{ fontSize: "1.5rem", fontWeight: 700 }}>
              Amplify your impact.
            </h3>
            <p style={{ marginTop: "1rem" }}>
              We will plant a tree for every friend or colleague you refer.
            </p>
            <p style={{ marginTop: "1rem" }}>
              Just one person reducing ten tons of CO2 can has the same impact
              as planting 150 trees
              <a
                style={{ color: "var(--green)", textDecoration: "none" }}
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.epa.gov/energy/greenhouse-gases-equivalencies-calculator-calculations-and-references"
              >
                *
              </a>
              !
            </p>
            <p style={{ marginTop: "1rem" }}>
              Share your link below to help change Earth:
            </p>
            <div
              style={{
                marginTop: "0.2rem",
                display: "grid",
                gridTemplateColumns: "5fr 1fr",
              }}
            >
              <input
                value={`https://youchangeearth.org/?ref_code=${refCode}`}
                style={{
                  padding: "0.5rem",
                  width: "100%",
                  border: "1px solid var(--green)",
                  borderRadius: "5px 0 0 5px",
                  outline: "none",
                }}
                id="ref_link"
              />
              <button
                style={{
                  width: "100%",
                  border: `1px solid ${backgroundColor}`,
                  borderRadius: "0 5px 5px 0",
                  background: `${backgroundColor}`,
                  color: `${copyColor}`,
                  transition: "all 0.5s ease",
                }}
                onClick={handleCopy}
              >
                {copyText}
              </button>
            </div>
          </div>
        </MainContainer>
        {/* <button onClick={() => console.log(refData)}>Show Ref Data</button>
        <button onClick={() => console.log(refCode)}>Show ref code</button> */}
      </div>
    </div>
  )
}

export default ReferralCard
