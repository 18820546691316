import React from "react"

const EmbeddedEntry = ({ fields }) => {
  const langCode = "en-US"
  return (
    <a
      style={{ textDecoration: "none", color: "inherit" }}
      href={"/guides/" + fields.slug[langCode]}
      target="_blank"
      rel="noreferrer"
    >
      <div className="post-item">
        <img
          style={{
            maxWidth: "80px",
            float: "left",
            marginRight: "20px",
          }}
          src={fields.icon[langCode].fields.file[langCode].url}
          alt="Icon for card here"
        />
        <div>
          <span
            style={{
              marginBottom: "5px",
              fontSize: "0.75em",
              borderRadius: "0.45em",
              border: "1px solid rgba(0,0,0,0.1)",
              padding: "0 5px",
            }}
          >
            <span role="img" aria-label="clock">
              ⏰{" "}
            </span>
            {fields.time !== undefined && fields.time[langCode]}
          </span>{" "}
          <span
            style={{
              marginBottom: "5px",
              fontSize: "0.75em",
              borderRadius: "0.45em",
              border: "1px solid rgba(0,0,0,0.1)",
              padding: "0 5px",
            }}
          >
            <span role="img" aria-label="people">
              👨‍👩‍👧{" "}
            </span>{" "}
            {fields.category !== undefined && fields.category[langCode]}
          </span>
          <h4 style={{ fontWeight: "700" }}>{fields.title[langCode]}</h4>
          <p>{fields.atAGlance[langCode]}</p>
        </div>
      </div>
    </a>
  )
}

export default EmbeddedEntry
