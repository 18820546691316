import React, { useState, useEffect } from "react"
import firebase from "gatsby-plugin-firebase"
import DefaultLayout from "../layouts/defaultLayout"
import SEO from "../components/seo"
import { useAuth } from "gatsby-theme-firebase"
import DashboardCard from "../components/dashboardCard"
import Lottie from "lottie-react-web"
import animation from "../components/lottie-dashboard-impact.json"
import Header from "../components/header"
import Footer from "../components/footer"
import useHasMounted from "../utils/useHasMounted"
import { Link } from "gatsby"
import ReferralCard from "../components/referralCard"
import ImpactSection from "../components/impactSection"

const logout = () => {
  firebase
    .auth()
    .signOut()
    .then(function() {
      alert("You have been signed out.")
    })
    .catch(function(error) {
      console.log(error)
    })
}

const Dashboard = () => {
  const hasMounted = useHasMounted()
  const { isLoading, isLoggedIn, profile } = useAuth()
  const [userData, setUserData] = useState()
  const [refData, setRefData] = useState([])
  const [refCode, setRefCode] = useState("")
  const totalNumberOfProjects = 14

  const doneProjects = []
  useEffect(() => {
    if (userData) {
      userData.interests.map(card => {
        if (card.id && !doneProjects.includes(card.id)) {
          doneProjects.push(card.id)
        }
      })
    }
  }, [userData])

  useEffect(() => {
    if (profile && !isLoading) {
      firebase
        .firestore()
        .collection(process.env.GATSBY_FIRESTORE_USERS)
        .doc(profile.email)
        .get()
        .then(function(doc) {
          if (doc.exists) {
            // console.log("Projects data:", doc.data())

            setUserData(doc.data())
            const code = window.btoa(doc.data().email)
            setRefCode(code)
          } else {
            // doc.data() will be undefined in this case
            console.log("No such user!")
          }
        })
        .catch(function(error) {
          console.log("Error getting user:", error)
        })
    }
  }, [profile, isLoading])

  useEffect(() => {
    if (userData && !isLoading) {
      const refs = userData.referredTo
      if (refs && Array.isArray(refs) && refs.length > 0) {
        // mapReferrals(refs)
      }
    }
  }, [userData, isLoading])

  // const mapReferrals = refs => {
  //   refs.map(ref => {
  //     firebase
  //       .firestore()
  //       .collection(process.env.GATSBY_FIRESTORE_USERS)
  //       .doc(ref)
  //       .get()
  //       .then(function(doc) {
  //         if (doc.exists) {
  //           setRefData(arr => [...arr, doc.data()])
  //         } else {
  //           console.log("No such ref user!")
  //         }
  //       })
  //       .catch(function(error) {
  //         console.log("Error getting ref user:", error)
  //       })
  //   })
  // }

  if (isLoading) {
    return (
      <DefaultLayout>
        <SEO title="Dashboard" />
        <p>Initialising User...</p>
      </DefaultLayout>
    )
  }
  if (isLoggedIn && userData) {
    return (
      <div>
        <Header siteTitle="Dashboard | You Change Earth" />
        <main
          style={{
            margin: `50px auto`,
            maxWidth: 1366,
            padding: `0 1.0875rem 1.45rem`,
            minWidth: "70%",
            flex: 1,
          }}
        >
          <SEO title="Dashboard" />
          <button
            onClick={logout}
            className="float-right mr-6"
            style={{ color: "var(--green)" }}
          >
            Log out
          </button>
          <div className="flex flex-col justify-center">
            <h1 className="text-3xl font-medium text-center text-grey-900 pb-2 ml-6 ">
              Congrats <span className="font-bold">{userData.name}</span>{" "}
              welcome to your climate action Dashboard!
            </h1>

            <p className="text-gray-600 ml-6 text-center">
              Check out your projects and track your progress in using your You
              Change Earth dashboard!
            </p>
          </div>
          <br></br>
          <br></br>
          <br></br>
          <section
            className="cardlist"
            style={{
              display: "flex",
              alignItems: "center",
              flexFlow: "row wrap",
              justifyContent: "center",
            }}
          >
            {userData.interests.map(card => {
              return (
                <div style={{ margin: "1rem" }}>
                  <DashboardCard
                    id={card.id}
                    name={card.name}
                    description={card.description}
                    selected={card.selected}
                    nextSteps={card.nextSteps}
                    icon={card.icon}
                    slug={card.slug}
                    tasks={card.tasks}
                    style={{
                      display: "flex",
                      height: "100%",
                      justifyContent: "center",
                      margin: "2rem",
                      marginBottom: "2.5rem",
                      alignItems: "center",
                      boxShadow:
                        "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
                    }}
                  />
                </div>
              )
            })}
          </section>
          {profile && userData.interests.length < totalNumberOfProjects && (
            <div
              style={{
                marginTop: "2rem",
                textAlign: "center",
                minWidth: 0,
              }}
            >
              <button
                className="button"
                style={{
                  padding: "0.5rem 1rem",
                  backgroundColor: "var(--green)",
                  fontSize: "2rem",
                  outline: "none",
                  marginTop: "1rem",
                  borderRadius: "2rem",
                }}
              >
                <Link
                  to="/add-project"
                  state={{
                    doneProjects: doneProjects,
                    email: profile.email,
                  }}
                  style={{
                    color: "white",
                    textDecoration: "none",
                  }}
                >
                  ＋
                </Link>
              </button>
            </div>
          )}
          <ReferralCard
            refCode={refCode}
            userData={userData}
            refData={refData}
            style={{
              display: "flex",
              height: "100%",
              justifyContent: "center",
              margin: "1rem",
              alignItems: "center",
              boxShadow:
                "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
            }}
          />
        </main>
        <Lottie
          options={{
            animationData: animation,
          }}
          style={{ cursor: "default" }}
        />
        <ImpactSection userData={userData} />
        <Footer />
      </div>
    )
  }
  if (hasMounted)
    return (
      <DefaultLayout>
        <SEO title="Dashboard" />
        <p>You are not logged in.</p>
      </DefaultLayout>
    )
}

export default Dashboard
