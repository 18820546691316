import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

const MainContainer = styled.div`
  background-color: hsl(192.5, 81.5%, 12.7%);
  color: white;
  display: flex;
  flex-flow: row wrap;
  padding: 1rem;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding: 2rem;
    padding-bottom: 4rem;
  }
`

const Title = styled.h3`
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  flex-basis: 100%;
`

const Subtitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 500;
`

const SubContainer = styled.div`
  //   display: grid;
  //   grid-template-columns: 1fr 1fr;
`

const ImpactSection = ({ userData }) => {
  const [timeProjects, setTimeProjects] = useState(0)
  const [carbon, setCarbon] = useState(0)
  const [trees, setTrees] = useState(0)
  const [dollars, setDollars] = useState(0)

  const data = useStaticQuery(graphql`
    query {
      allContentfulProjectNew {
        nodes {
          id
          projectType
          tonsOfCarbon
        }
      }
    }
  `)

  useEffect(() => {
    if (data.allContentfulProjectNew && userData) {
      data.allContentfulProjectNew.nodes.map(item => {
        userData.interests.map(interest => {
          if (interest.id === item.id) {
            if (item.projectType === "time" || item.projectType === "career") {
              setTimeProjects(number => number + 1)
            } else {
              setCarbon(number => number + item.tonsOfCarbon)
              setDollars(number => number + 50 * item.tonsOfCarbon)
              setTrees(number => number + 17 * item.tonsOfCarbon)
            }
          }
        })
      })
    }
  }, [data])
  return (
    <MainContainer>
      <div
        style={{
          justifyContent: "center",
          display: "flex",
          marginBottom: "1rem",
        }}
      >
        <div style={{ maxWidth: "400px" }}>
          <Title>You're pledging to:</Title>
          {userData.interests.map(interest => {
            return (
              <>
                <div
                  style={{
                    display: "flex",
                    margin: "0.75rem 0",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{ maxWidth: "2rem", marginRight: "0.5rem" }}
                    src={interest.icon.file.url}
                    alt="icon"
                  />
                  <p>{interest.name}</p>
                </div>
              </>
            )
          })}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexFlow: "row wrap",
          justifyContent: "center",
        }}
      >
        <Title style={{ textAlign: "center" }}>Your Impact</Title>
        <SubContainer>
          {timeProjects !== 0 && (
            <div style={{ padding: "0 1rem 1rem 1rem", marginTop: "-0.4rem" }}>
              <Subtitle>
                <span
                  style={{
                    color: "var(--green)",
                    fontWeight: 700,
                    fontSize: "2rem",
                    marginRight: "0.2rem",
                  }}
                >
                  {timeProjects}
                </span>
                {"  "}
                ways you're using our time for the future!
              </Subtitle>
            </div>
          )}
          {carbon !== 0 && (
            <div style={{ padding: "0 1rem 1rem 1rem", marginTop: "-0.4rem" }}>
              <Subtitle>
                <span
                  style={{
                    color: "var(--green)",
                    fontWeight: 700,
                    fontSize: "2rem",
                    marginRight: "0.2rem",
                  }}
                >
                  {carbon}
                </span>
                {"  "}
                tons of CO2 you will be reducing.
              </Subtitle>
              <p style={{ marginTop: "1rem" }}>This is equivalent to:</p>
              <div
                style={{
                  display: "flex",
                  flexFlow: "row wrap",
                  fontSize: "1.2rem",
                }}
              >
                <p>{trees} </p>
                <span className="mr-1 mt-1 ml-1">
                  <svg
                    width="15"
                    height="16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.398 7.83a2.744 2.744 0 0 0-.99-.772 3.634 3.634 0 0 0-2.525-4.564A2.895 2.895 0 0 0 9.048.843 2.896 2.896 0 0 0 6.998 0c-.771 0-1.5.3-2.048.842a2.895 2.895 0 0 0-.835 1.653A3.634 3.634 0 0 0 1.59 7.059 2.733 2.733 0 0 0 0 9.536a2.73 2.73 0 0 0 2.726 2.725h8.545a2.73 2.73 0 0 0 2.727-2.726c0-.618-.213-1.224-.6-1.705z"
                      fill="#00CC76"
                    />
                    <path
                      d="M14.002 9.536a2.732 2.732 0 0 0-1.59-2.478 3.634 3.634 0 0 0-2.525-4.564A2.895 2.895 0 0 0 9.052.843 2.896 2.896 0 0 0 7.002 0v12.262h4.273a2.73 2.73 0 0 0 2.727-2.726z"
                      fill="#00AA95"
                    />
                    <path
                      d="M9.223 6.913l-.674-.674L7.477 7.31V4.151h-.954v1.44L5.452 4.519l-.675.674L6.523 6.94v2.09L5.452 7.959l-.675.674 1.746 1.746v5.425h.954V8.659l1.746-1.746z"
                      fill="#C1674C"
                    />
                    <path
                      d="M8.551 6.239L7.48 7.31V4.151h-.477v11.652h.477V8.659l1.746-1.746-.675-.674z"
                      fill="#8C4A50"
                    />
                  </svg>
                </span>
                <p> | ${dollars}</p>
              </div>
              <p>
                <a
                  href="https://www.epa.gov/energy/greenhouse-gases-equivalencies-calculator-calculations-and-references"
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{
                    color: "var(--green)",
                    fontSize: "0.6rem",
                    textDecoration: "none",
                  }}
                >
                  [1]
                </a>
                <a
                  href="https://www.imf.org/external/pubs/ft/fandd/2019/12/pdf/fd1219.pdf"
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{
                    color: "var(--green)",
                    fontSize: "0.6rem",
                    textDecoration: "none",
                  }}
                >
                  [2]
                </a>
              </p>
            </div>
          )}
        </SubContainer>
      </div>
    </MainContainer>
  )
}

export default ImpactSection
