// Calculations from https://www.epa.gov/energy/greenhouse-gas-equivalencies-calculator
//  uses metric tons

/**
 * Converts tons of CO2 to more understandable units
 *
 * @param {number}  - metric tons
 * @returns {Array} array of amount, text object
 */
const convertCo2Tons = (tons) => {
  const passengerVehiclesDriven = (tons * 0.216).toFixed(2)
  const gallonsOfGasoline = (tons * 113).toFixed(2)
  const acresOfUsForest = (tons * 1.3).toFixed(2)
  const homeEnergyUse = (tons * 0.115).toFixed(2)

  return [
    {
      amount: passengerVehiclesDriven,
      text: `greenhouse gas emissions from ${passengerVehiclesDriven} passenger vehicles driven for one year`
    },
    {
      amount: gallonsOfGasoline,
      text: `CO2 emissions from ${gallonsOfGasoline} gallons of gasoline consumed`
    },
    {
      amount: acresOfUsForest,
      text: `carbon sequestered by ${acresOfUsForest} acres of U.S. forests in on year`
    },
    {
      amount: homeEnergyUse,
      text: `CO2 emissions from ${homeEnergyUse} home's energy use from one year`
    }
  ]
}

export default convertCo2Tons;