import React, { useState, useEffect } from "react"
import Modal from "react-responsive-modal"
import { useStaticQuery, graphql } from "gatsby"
import { INLINES, BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import EmbeddedEntry from "./embeddedEntry"
import convertCo2Tons from "../utils/co2Calculations"
import styled from "styled-components"
import foodBackground from "../images/food-background.jpg"
import { Link } from "gatsby"
import { useAuth } from "gatsby-theme-firebase"
import firebase from "gatsby-plugin-firebase"
import ProgressBar from "../components/progressBar"

const website_url = "https://youchangeearth.org"
const options = {
  renderText: text =>
    text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
  renderNode: {
    [INLINES.HYPERLINK]: node => {
      return (
        <a
          href={node.data.uri}
          target={`${
            node.data.uri.startsWith(website_url) ? "_self" : "_blank"
          }`}
          rel={`${
            node.data.uri.startsWith(website_url) ? "" : "noopener noreferrer"
          }`}
          style={{ textDecoration: "none" }}
        >
          {node.content[0].value}
        </a>
      )
    },
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      return <EmbeddedEntry fields={node.data.target.fields} />
    },
  },
}

const CustomDiv = styled.div`
  min-width: 0;
`

const MainContainer = styled(CustomDiv)`
  background-image: url(${foodBackground});
  background-repeat: no-repeat;
  background-position: center;
  flex-basis: 100%;
  padding: 10rem 1rem 2rem 1rem;
  border-radius: 10px 10px 0 0;
`
const MainTitle = styled.h2`
  color: #fff;
  align-self: end;
  font-size: 2rem;
  font-weight: 500;
`

const modalStyles = {
  modal: {
    borderRadius: "10px",
  },
}

const DashboardCard = props => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const { isLoggedIn, profile } = useAuth()
  const [doneTasks, setDoneTasks] = useState(0)
  const data = useStaticQuery(graphql`
    query {
      contentfulAsset(title: { eq: "arrow" }) {
        file {
          url
        }
        title
        id
      }
    }
  `)
  useEffect(() => {
    if (isLoggedIn && profile) {
      firebase
        .firestore()
        .collection(process.env.GATSBY_FIRESTORE_USERS)
        .doc(profile.email)
        .get()
        .then(function(doc) {
          if (doc.exists) {
            const tasks = doc.data().tasks
            tasks.forEach(profileTask => {
              props.tasks.forEach(projectTask => {
                if (profileTask.id === projectTask.id) {
                  if (
                    profileTask.status === "done" ||
                    profileTask.status === "pledged" ||
                    profileTask.status === "no"
                  ) {
                    setDoneTasks(doneTasks => doneTasks + 1)
                  }
                }
              })
            })
          }
        })
    }
  }, [isLoggedIn, profile])
  return (
    <div className={props.className + " card"}>
      {props.children}
      <div className="card-content">
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 5fr",
            gridGap: "1em",
            alignItems: "center",
          }}
        >
          {props.icon != null && (
            <img
              style={{ width: "100%" }}
              src={props.icon.file.url}
              alt="Icon for card here"
            />
          )}
          <h5 className="text-lg font-semibold">{props.name}</h5>
        </div>
        {props.co2Savings && (
          <>
            <span
              style={{
                marginBottom: "5px",
                fontSize: "0.75em",
                borderRadius: "0.45em",
                border: "1px solid rgba(0,0,0,0.1)",
                padding: "0 5px",
              }}
            >
              <span role="img" aria-label="clock">
                ⛽{" "}
              </span>
              {convertCo2Tons(props.co2Savings)[1].amount}
            </span>
            {/* <b>Potential CO2 Savings: {props.co2Savings} tons/year, </b>
              equivalent to {convertCo2Tons(props.co2Savings)[0].text}. */}
            {console.log(convertCo2Tons(props.co2Savings))}
          </>
        )}
        {props.time && (
          <span
            style={{
              marginBottom: "5px",
              fontSize: "0.75em",
              borderRadius: "0.45em",
              border: "1px solid rgba(0,0,0,0.1)",
              padding: "0 5px",
            }}
          >
            <span role="img" aria-label="clock">
              ⏰{" "}
            </span>
            {props.time}
          </span>
        )}
        <br></br>
        {props.description && (
          <p>{documentToReactComponents(props.description.json, options)}</p>
        )}

        {/* <div className="flex flex-row ">
          <span className="mr-2 mt-1">
            <svg
              width="15"
              height="16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.398 7.83a2.744 2.744 0 0 0-.99-.772 3.634 3.634 0 0 0-2.525-4.564A2.895 2.895 0 0 0 9.048.843 2.896 2.896 0 0 0 6.998 0c-.771 0-1.5.3-2.048.842a2.895 2.895 0 0 0-.835 1.653A3.634 3.634 0 0 0 1.59 7.059 2.733 2.733 0 0 0 0 9.536a2.73 2.73 0 0 0 2.726 2.725h8.545a2.73 2.73 0 0 0 2.727-2.726c0-.618-.213-1.224-.6-1.705z"
                fill="#00CC76"
              />
              <path
                d="M14.002 9.536a2.732 2.732 0 0 0-1.59-2.478 3.634 3.634 0 0 0-2.525-4.564A2.895 2.895 0 0 0 9.052.843 2.896 2.896 0 0 0 7.002 0v12.262h4.273a2.73 2.73 0 0 0 2.727-2.726z"
                fill="#00AA95"
              />
              <path
                d="M9.223 6.913l-.674-.674L7.477 7.31V4.151h-.954v1.44L5.452 4.519l-.675.674L6.523 6.94v2.09L5.452 7.959l-.675.674 1.746 1.746v5.425h.954V8.659l1.746-1.746z"
                fill="#C1674C"
              />
              <path
                d="M8.551 6.239L7.48 7.31V4.151h-.477v11.652h.477V8.659l1.746-1.746-.675-.674z"
                fill="#8C4A50"
              />
            </svg>
          </span>
          <span>15 trees</span>
        </div>
        <div className="flex flex-row ">
          <span className="mr-2 mt-1">
            <svg
              width="14"
              height="11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.629 6.153a2.988 2.988 0 0 1-.422-.587 3.111 3.111 0 0 1-2.095-.44A2.795 2.795 0 0 0 0 7.834v1.517h2.436A3.599 3.599 0 0 1 4.63 6.153zm-.878-1.37h.146a2.884 2.884 0 0 1-.098-.751c0-1.306.796-2.448 1.917-2.938A2.353 2.353 0 0 0 3.735.017 2.38 2.38 0 0 0 1.363 2.4a2.394 2.394 0 0 0 2.388 2.383zm8.138.342a3.155 3.155 0 0 1-1.64.474 4.26 4.26 0 0 1-.456-.033c-.113.212-.26.408-.422.587a3.649 3.649 0 0 1 2.193 3.216H14V7.85a2.8 2.8 0 0 0-2.111-2.726zm-1.707-1.094c0 .261-.032.506-.097.751h.146a2.38 2.38 0 0 0 2.371-2.383c0-1.306-1.04-2.399-2.355-2.399-.828 0-1.56.424-1.981 1.077a3.245 3.245 0 0 1 1.916 2.954zm.572 5.452a2.781 2.781 0 0 0-2.112-2.71 3.155 3.155 0 0 1-1.64.474c-.601 0-1.17-.18-1.64-.474a2.795 2.795 0 0 0-2.112 2.71V11h7.504V9.483z"
                fill="#366DDC"
              />
              <path
                d="M7 1.631A2.38 2.38 0 0 0 4.63 4.014 2.38 2.38 0 0 0 7 6.397a2.38 2.38 0 0 0 2.371-2.383 2.38 2.38 0 0 0-2.37-2.383z"
                fill="#366DDC"
              />
            </svg>
          </span>
          <span>15 trees</span>
        </div> */}

        <div style={{ display: "flex", margin: "1rem 0" }}>
          <Link
            to={`/guides/${props.slug}`}
            className="learn-more"
            style={{ outline: "none", color: "white", textDecoration: "none" }}
          >
            <div
              className="button homepageButton"
              style={{
                color: "white",
                textAlign: "center",
                textDecoration: "none",
                padding: "0.5rem 0.5rem",
              }}
            >
              {doneTasks === props.tasks.length ? (
                <p
                  style={{
                    fontSize: "0.9rem",
                    fontWeight: 700,
                    textDecoration: "none",
                  }}
                >
                  View completed project
                </p>
              ) : (
                <p
                  style={{
                    fontSize: "0.9rem",
                    fontWeight: 700,
                    textDecoration: "none",
                  }}
                >
                  Complete your project
                </p>
              )}
            </div>
          </Link>
        </div>
        <ProgressBar doneTasks={doneTasks} totalTasks={props.tasks.length} />

        <Modal
          open={modalIsOpen}
          onClose={() => {
            setModalIsOpen(false)
          }}
          styles={modalStyles}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              margin: "-19.2px",
            }}
          >
            <MainContainer>
              <MainTitle>{props.name}</MainTitle>
            </MainContainer>
            <div
              style={{
                backgroundColor: "#FAFAFA",
                paddingBottom: "2rem",
                width: "99.9%",
              }}
            >
              {props.description && (
                <p
                  style={{
                    padding: "2rem",
                    paddingBottom: "0",
                  }}
                >
                  {documentToReactComponents(props.description.json, options)}
                </p>
              )}
              <h3
                style={{
                  margin: "2rem 0 2rem 0",
                  width: "100%",
                  padding: "0 2rem 0 2rem",
                  fontSize: "1.5rem",
                }}
              >
                <b>Why is this important?</b>
              </h3>{" "}
              {props.why && (
                <p
                  style={{
                    width: "100%",
                    paddingLeft: "2rem",
                    fontSize: "1.5rem",
                    padding: "0 2rem 0 2rem",
                  }}
                >
                  {documentToReactComponents(props.why.json, options)}
                </p>
              )}
            </div>
            <h3
              style={{
                margin: "1rem 0 2rem 0",
                width: "100%",
                padding: "0 2rem 0 2rem",
                fontSize: "1.5rem",
              }}
            >
              <b>Tasks to expect in this project</b>
            </h3>
            <div style={{ marginBottom: "1rem" }}>
              {props.tasks &&
                props.tasks.map(task => (
                  <div style={{ padding: "0 2rem 1rem 2rem" }}>
                    <h2 style={{ marginBottom: "5px", fontSize: "1.3rem" }}>
                      {task.name}
                    </h2>
                    {task.description && (
                      <p>
                        {documentToReactComponents(
                          task.description.json,
                          options
                        )}
                      </p>
                    )}
                  </div>
                ))}
            </div>
          </div>
          <br />
          <br />
          <br></br>
          {props.nextSteps != null && (
            <div style={{ padding: "0px 20px 0px 10px" }}>
              {documentToReactComponents(props.nextSteps.json, options)}
            </div>
          )}
          <div
            style={{
              position: "absolute",
              height: "70px",
              background: "#1db954",
              color: "white",
              width: "100%",
              overflow: "hidden",
              zIndex: "99",
              display: "flex",
              justifyContent: "flex-end",
              bottom: "0",
              marginLeft: "-19px",
              borderRadius: "0 0 10px 10px",
              alignItems: "center",
              paddingRight: "1rem",
              columnGap: "1rem",
            }}
          >
            <button
              onClick={() => setModalIsOpen(false)}
              style={{
                outline: "none",
              }}
            >
              <b>Close</b>
            </button>
            <button
              onClick={() => {
                props.onClick()
                setModalIsOpen(false)
              }}
              style={{
                backgroundColor: "white",
                color: "black",
                padding: "0.5rem 1.5rem 0.5rem 1.5rem",
                borderRadius: "10px",
              }}
            >
              {props.selected ? <b>Remove project</b> : <b>Add project</b>}
            </button>
          </div>
        </Modal>
      </div>
    </div>
  )
}

export default DashboardCard
