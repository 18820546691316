import React, { useEffect, useState } from "react"

const ProgressBar = props => {
  const [completed, setCompleted] = useState(0)
  const { doneTasks, totalTasks } = props
  useEffect(() => {
    if (doneTasks && totalTasks) {
      if (doneTasks / totalTasks <= 1) {
        setCompleted(doneTasks / totalTasks)
      } else {
        setCompleted(0)
      }
    }
  })

  const containerStyles = {
    height: 25,
    width: "95%",
    backgroundColor: "#e0e0de",
    borderRadius: 50,
  }

  const fillerStyles = {
    height: "100%",
    width: `${completed * 100}%`,
    transition: "width 0.5s",
    backgroundColor: "var(--green)",
    borderRadius: "inherit",
    textAlign: "right",
  }

  const labelStyles = {
    padding: 5,
    color: "white",
    fontWeight: "bold",
    marginRight: "1rem",
  }
  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
        <span style={labelStyles}>{Math.round(completed * 100)} %</span>
      </div>
    </div>
  )
}

export default ProgressBar
